<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-blue py-lg-5" ref="navbar">
    <div class="container-fluid">
      <router-link id="logo" :to="menu.home.url" class="navbar-brand me-0">
        <img
            src="@/assets/images/logo-rizzitiello.png"
            alt="Logo Studio Rizzitiello"
            class="mx-md-4"
            width="190"
        />
      </router-link>
      <div v-if="!responsive() && management" style="color: #fff; font-size: 1.5rem">
        <NotificationIcon />
      </div>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
        <ul v-if="!management" class="navbar-nav">
          <li class="nav-item">
            <router-link
                :to="'/'"
                class="nav-link text-white active"
                aria-current="page"
            >
              {{ menu.home.title }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
                :to="menu.about.url"
                class="nav-link text-white"
            >
              {{ menu.about.title }}
            </router-link>
          </li>
          <li class="nav-item dropdown">
            <router-link
                :to="menu.services.url"
                class="nav-link text-white dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"
            >
              {{ menu.services.title }}
            </router-link>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <router-link :to="menu.cafCgn.url" class="dropdown-item" href="#">
                  {{ menu.cafCgn.title }}
                </router-link>
                <router-link :to="menu.consulenzaFiscaleEContabile.url" class="dropdown-item" href="#">
                  {{ menu.consulenzaFiscaleEContabile.title }}
                </router-link>
                <router-link :to="menu.consulenzaLavoro.url" class="dropdown-item" href="#">
                  {{ menu.consulenzaLavoro.title }}
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <router-link
                :to="menu.news.url"
                class="nav-link text-white"
            >
              {{ menu.news.title }}
            </router-link>
          </li>
          <li class="nav-item">
            <a class="nav-link text-white" :href="menu.contact.url">
              {{ menu.contact.title }}
            </a>
          </li>
          <li class="nav-item">
            <p class="nav-link text-white cursor-pointer mb-0" @click="redirect('/management/dashboard')">
              Area Riservata
            </p>
          </li>
        </ul>
        <ul v-if="management && !userEmpty" class="navbar-nav">
          <li class="nav-item">
            <p class="nav-link text-white cursor-pointer mb-0" @click="redirect('/management/dashboard')">
              {{ fullName }}
            </p>
          </li>
          <li v-if="responsive()" class="nav-item">
            <p
              class="nav-link text-white cursor-pointer mb-0 position-relative"
            >
              <NotificationIcon />
            </p>
          </li>
          <li class="nav-item">
            <p class="nav-link text-white cursor-pointer mb-0" @click="logout()">
              Esci
            </p>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import router from '@/router/router.json';
import { logout } from "@/utilities/auth";
import { userMixin } from "@/mixins/user.mixin";
import NotificationIcon from "@/components/actions-bar/notifications/NotificationIcon.vue";

const MIN_SCROLL_Y = 150;

export default defineComponent({
  name: "NavbarContent",
  components: {NotificationIcon},
  mixins: [userMixin],
  props: {
    management: {
      type: Boolean,
      required: false,
      default: false,
    },
    user: {
      type: Object,
      required: false,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      menu: router,
    }
  },
  methods: {
    redirect(pathname: string) {
      window.location.href = pathname;
    },
    responsive() {
      return window.innerWidth > 972;
    },
    logout() {
      logout();
    }
  },
  mounted() {
    if (!location.href.includes(process.env.VUE_APP_AUTH_GUARD_PATHNAME)) {
      document.addEventListener('scroll', () => {
        if (!this.$refs.navbar) return;

        const y = window.scrollY;

        if (y > MIN_SCROLL_Y) {
          (this.$refs.navbar as HTMLElement).style.transition = '0.9s ease-in';
          (this.$refs.navbar as HTMLElement).style.position = 'fixed';
          (this.$refs.navbar as HTMLElement).style.top = '0';
          (this.$refs.navbar as HTMLElement).style.left = '0';
          (this.$refs.navbar as HTMLElement).style.width = '100%';
        } else {
          (this.$refs.navbar as HTMLElement).style.position = 'relative'
        }
      });
    }
  },
  unmounted() {
    document.removeEventListener('scroll', () => {/**/});
  }
})
</script>

<style scoped>
  nav.navbar {
    position: relative;
    z-index: 2;
    height: 80px;
    transition: 0.4s ease-in-out;
  }

  .navbar-nav li.nav-item {
    padding: 0 1.5rem;
    font-size: 1.3rem;
  }

  .dropdown-toggle::after {
    position: relative;
    top: 3px;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  ul.dropdown-menu li a:hover {
    color: #000!important;
  }

  a {
    position: relative;
  }

  .navbar-nav li.nav-item a.nav-link {
    padding-right: 0;
    padding-left: 0;
  }

  ul.dropdown-menu {
    border-radius: 0;
    background-color: #173759;
  }

  ul.dropdown-menu li a {
    color: #fff;
  }

  a#logo::before { display: none }

  .router-link-active::before,
  .router-link-exact-active::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid #fff;
    width: 30px;
    height: 1px;
  }

  .dropdown-item.router-link-exact-active::before {
    left: 16px;
  }

  .dropdown-item.active, .dropdown-item:active {
    background-color: #173759;
  }

  ul.navbar-nav {
    background-color: #173759;
    padding: 1rem 0;
  }

  @media screen and (min-width: 992px) {
    ul.navbar-nav {
      background-color: unset;
    }

    .navbar-nav li.nav-item {
      padding: 0 1.5rem;
      font-size: 1rem;
    }
  }

  @media screen and (min-width: 1200px) {
    .navbar-nav li.nav-item {
      padding: 0 1.5rem;
      font-size: 1.25rem;
    }
  }
</style>