const NEWS_BASE_URL = '/news/';

export default {
    props: {
        id: String,
    },
    methods: {
        redirectTo() {
            if (!this.id) return;

            this.$router.push(NEWS_BASE_URL + this.id);
        }
    }
}