<template>
  <section class="position-relative mb-5">
    <img
        class="gray-stripe"
        src="@/assets/images/banda-grigia.png"
        alt="Banda laterale grigia rotonda"
    />
    <div class="container py-5">
      <div class="row">
        <div class="col-12 d-flex justify-content-center">
          <div class="heading my-5">
            <div class="text-uppercase c-blue fw-bold text-center">
              Servizi
            </div>
            <h2 class="fw-bold">
              Le nostre Specializzazioni
            </h2>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-center justify-content-lg-between flex-wrap">
          <RoundedCard
              :image-name="'consulenza.jpg'"
              image-alt="Immagine consulenza fiscale e contabile"
              :description="'Consulenza <br /> Fiscale e Contabile'"
              :icon-name="'consulenza-ico.svg'"
              icon-alt="Icona consulenza fiscale e contabile"
              url="/services/consulenza-fiscale-contabile"
          />
          <RoundedCard
              :image-name="'lavoro.jpg'"
              image-alt="Immagine consulenza del lavoro"
              :description="'Consulenza <br /> del lavoro'"
              :icon-name="'lavoro-ico.svg'"
              icon-alt="Icona Lavoro"
              url="/services/consulenza-lavoro"
          />
          <RoundedCard
              :image-name="'caf.jpg'"
              image-alt="Immagine Caf e Cgn"
              description="Caf e Cgn"
              :icon-name="'caf-ico.svg'"
              icon-alt="Icona Caf e Cgn"
              url="/services/caf-e-cgn"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import RoundedCard from "@/components/cards/RoundedCard";

export default defineComponent({
  name: "SectionCards",
  components: { RoundedCard }
});
</script>

<style scoped>
  img.gray-stripe {
    position: absolute;
    top: 0;
    left: 0;
    width: 220px;
  }

  @media screen and (min-width: 768px) {
    img.gray-stripe {
      width: 370px;
    }
  }
</style>