import axios from 'axios';
import {
    authInterceptor, errorInterceptor,
    responseInterceptor
} from '@/api/httpInterceptors';

const httpClient = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    timeout: 90000,
    headers: {
        'Content-Type': 'application/json'
    }
});

httpClient.defaults.withCredentials = true;

httpClient.interceptors.request.use(authInterceptor);
httpClient.interceptors.response.use(responseInterceptor, errorInterceptor);

export default httpClient;