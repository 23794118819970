import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/home/Home.vue';
import { dashboardBar } from "@/router/bar";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    meta: { layout: 'slider' },
    component: Home
  },
  {
    path: '/services',
    name: 'Services',
    meta: { layout: 'default' },
    redirect: '/services/caf-e-cgn',
    component: () => import(/* webpackChunkName: "servicesIndex" */ '../views/services/Index.vue'),
    children: [
      {
        path: 'caf-e-cgn',
        name: 'Caf-Cgn',
        meta: {
          layout: 'default',
          title: 'Servizi Caf e Dichiarativi',
          imageName: 'caf-and-cgn.png',
        },
        component: () => import(/* webpackChunkName: "services" */ '../views/services/CafAndCgn.vue')
      },
      {
        path: 'consulenza-fiscale-contabile',
        name: 'ConsulenzaFiscaleContabile',
        meta: {
          layout: 'default',
          title: 'Consulenza Fiscale e Contabile',
          imageName: 'cons-fisc-cont.png',
        },
        component: () => import(/* webpackChunkName: "services" */ '../views/services/ConsulenzaFiscaleContabile.vue')
      },
      {
        path: 'consulenza-lavoro',
        name: 'ConsulenzaLavoro',
        meta: {
          layout: 'default',
          title: 'Consulenza Del Lavoro',
          imageName: 'header-lavoro.png',
        },
        component: () => import(/* webpackChunkName: "services" */ '../views/services/ConsulenzaLavoro.vue')
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      layout: 'default',
      title: 'About',
      imageName: 'bg-about.png',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/about/About.vue')
  },
  {
    path: '/news/:id',
    name: 'Article',
    meta: {
      layout: 'news'
    },
    component: () => import('../views/news/Article.vue'),
  },
  {
    path: '/news',
    name: 'News',
    meta: { layout: 'news' },
    component: () => import('../views/news/News.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    meta: { layout: 'blank', login: true },
    component: () => import('../views/management/Login.vue'),
  },
  {
    path: '/management',
    name: 'Management',
    meta: { layout: 'dashboard' },
    redirect: '/management/dashboard',
    component: () => import('../views/management/Index.vue'),
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        meta: {
          layout: 'dashboard',
          bar: dashboardBar
        },
        component: () => import('../views/management/dashboard/Dashboard.vue'),
      },
      {
        path: 'profile',
        name: 'Profile',
        meta: { layout: 'dashboard' },
        component: () => import('../views/management/profile/Profile.vue'),
      },
      {
        path: 'practices',
        name: 'Practices',
        meta: { layout: 'dashboard' },
        component: () => import('../views/management/documents/Practices.vue'),
      },
      {
        path: 'practices/:id/documents',
        name: 'Documents',
        meta: { layout: 'dashboard' },
        component: () => import('../views/management/documents/Documents.vue'),
      },
      {
        path: 'users',
        name: 'Users',
        meta: { layout: 'dashboard' },
        component: () => import('../views/management/users/Users.vue'),
      },
      {
        path: 'user/:id',
        name: 'UserIndex',
        meta: { layout: 'dashboard' },
        component: () => import('../views/management/users/Index.vue'),
        children: [
          {
            path: 'profile',
            name: 'UserProfile',
            meta: { layout: 'dashboard' },
            component: () => import('../views/management/users/UserProfile.vue'),
          }
        ]
      },
      {
        path: 'practices/archived',
        name: 'PracticesArchived',
        meta: { layout: 'dashboard' },
        component: () => import('../views/management/documents/TrashedPractices.vue'),
      },
      {
        path: 'articles',
        name: 'HandleNews',
        meta: { layout: 'dashboard' },
        component: () => import('../views/management/news/HandleNews.vue'),
      },
      {
        path: 'articles/:id',
        name: 'HandleSpecificNews',
        meta: { layout: 'dashboard' },
        component: () => import('../views/management/news/HandleNews.vue'),
      },
      {
        path: 'articles/list',
        name: 'ArticlesList',
        meta: { layout: 'dashboard' },
        component: () => import('../views/management/news/NewsList.vue'),
      },
      {
        path: 'communications/list',
        name: 'CommunicationsList',
        meta: { layout: 'dashboard' },
        component: () => import('../views/management/news/CommunicationsList.vue'),
      },
      {
        path: 'communications/send',
        name: 'CommunicationsSend',
        meta: { layout: 'dashboard' },
        component: () => import('../views/management/news/CommunicationSend.vue'),
      },
      {
        path: 'communications',
        name: 'UserCommunications',
        meta: { layout: 'dashboard' },
        component: () => import('../views/management/news/Communications.vue'),
      }
    ]
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: () => {
    const app = document.getElementById('app');
    app && app.scrollIntoView();
  }
});


router.beforeEach( (to, from, next) => {
  if (to.matched.some(record => record.meta.login)) {
    const token = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);

    if (token) next({ name: 'Dashboard' });
  }

  next();
});

export default router;
