<template>
  <section class="sub-header">
    <img
        class="w-100 h-100 obj-fit-cover"
        :src="require(`@/assets/images/${getHeaderImage}`)"
        :alt="getHeaderTitle"
    />
    <h1 class="fw-bold text-uppercase text-white text-center">
      {{ getHeaderTitle }}
    </h1>
  </section>
</template>

<script>
export default {
  name: "HeaderWithTitle",
  computed: {
    getHeaderImage() {
      return this.$route.meta['imageName'] || 'caf-and-cgn.png';
    },
    getHeaderTitle() {
      return this.$route.meta['title'] || 'Immagine';
    }
  }
}
</script>

<style scoped>
  .sub-header {
    position: relative;
    width: 100%;
    height: 375px;
  }

  .sub-header h1 {
    position: absolute;
    top: calc(50% + 105px);
    left: 50%;
    transform: translate(-50%, -50%);
  }

  h1 {
    font-family: 'AvantGardeBookBT', sans-serif;
  }

  @media screen and (min-width: 992px) {
    .sub-header {
      height: 460px;
    }
  }
</style>