<template>
  <section id="contact" class="contact-us">
    <div class="container h-100">
      <div class="row h-100 px-lg-2">
        <div class="col-12 col-lg-6 h-100 bg-transparent-blue">
          <ContactForm />
        </div>
        <div class="col-12 col-lg-6 h-100 d-none d-lg-flex align-items-center">
          <div class="contact-us-label fw-bold c-blue px-4">
            <h1 class="mb-0 fw-bold">
              Contattaci
            </h1>
            <h2>
              per ricevere <br />
              maggiori <br />
              informazioni
            </h2>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ContactForm from "@/components/sections/section-contact-us/ContactForm";
export default {
  name: "SectionContactUs",
  components: {ContactForm}
}
</script>

<style scoped>
  .contact-us {
    background-image: url('../../../assets/images/contattaci.png');
    background-position: center;
    background-size: cover;
    height: 700px;
  }

  .contact-us-label h1 {
    font-size: 3.2rem;
  }

  .contact-us-label h2 {
    font-size: 2.8rem;
    font-family: 'Syne', sans-serif;
  }
</style>