import { defineComponent, PropType  } from 'vue';
import { User } from "@/types/User";

export const userMixin = defineComponent({
    props: {
        user: {
            type: Object as PropType<User>,
            required: false,
        }
    },
    computed: {
        userEmpty(): boolean {
            return Object.keys(this.user as User).length === 0;
        },
        fullName(): string {
            if (!this.user) return '';

            return `${this.user.name} ${this.user.surname}`
        }
    }
})