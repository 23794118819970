<template>
  <article
      v-if="title"
      class="single-article mb-5 mb-xl-0 bg-white px-4 py-5"
      @click="redirectTo"
  >
    <div class="overlay"></div>
    <img
        class="w-100 h-100 obj-fit-cover"
        :src="imageName"
        :alt="imageAlt"
    />
    <div class="content">
      <p class="fw-bold date c-blue text-uppercase mb-2">
        {{ data }}
      </p>
      <h1 class="mb-4 title">
        {{ title }}
      </h1>
      <h6 class="description">
        {{ description }}
      </h6>
    </div>
  </article>
</template>

<script>
import redirectMixin from "@/mixins/redirect.mixin.js";

export default {
  mixins: [ redirectMixin ],
  name: "SingleArticle",
  props: {
    data: String,
    title: String,
    description: String,
    imageName: String,
    imageAlt: String,
  },
}

</script>

<style scoped>
  .single-article {
    position: relative;
    width: 400px;
    height: 460px;
    border: 1px solid #173759;
    cursor: pointer;
  }

  .single-article .overlay {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0006;
    z-index: 2;
  }

  .single-article:hover .overlay {
    visibility: visible;
  }

  .single-article .content {
    position: relative;
    z-index: 3;
  }

  .single-article:hover {
    height: 400px;
  }

  .single-article:hover .content {
    top: 140px;
  }

  .single-article:hover .content .title,
  .single-article:hover .content .date {
    color: #fff;
  }

  .single-article img {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
    z-index: 1;
  }

  .single-article:hover img {
    display: block;
  }

  .single-article:hover .description {
    display: none;
  }

  .single-article .date {
    font-size: 1.2rem;
  }

  .single-article h1 {
    font-size: 2.15rem;
  }

  .single-article .description {
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 133px;
    height: 133px;
    word-break: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
  }
</style>