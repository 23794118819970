<template>
  <section v-if="news.length" class="bg-lite-white py-5">
    <div class="container">
      <div class="row ">
        <div class="col-6">
          <h1 class="fw-bold">
            Ultime News
          </h1>
        </div>
        <div class="col-6 d-flex justify-content-end align-items-end">
          <h4 class="fw-bold text-uppercase">
            <router-link to="/news" class="text-decoration-none c-blue">
              Leggi tutte
            </router-link>
            <hr class="w-50 mt-2 c-blue" />
          </h4>
        </div>
      </div>
      <div class="row mt-5">
        <div class="news col-12 d-flex justify-content-center justify-content-lg-between flex-wrap">
          <div
              v-for="article in news"
              :key="article.id"
          >
            <SingleArticle
                :data="new Date(article.created_at).toLocaleDateString()"
                :title="article.title"
                :description="article.description"
                :image-name="article.image_url"
                image-alt="Immagine dell'articolo"
                id="1"
            />
          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SingleArticle from "@/components/sections/section-news/SingleArticle";
import {paginateArticles} from "@/api/news.api";
export default {
  name: "SectionNews",
  components: { SingleArticle },
  data() {
    return {
      news: []
    }
  },
  methods: {
    async getRecentNews() {
      const response = await paginateArticles(1);
      this.news = response.data;
    }
  },
  mounted() {
    this.getRecentNews();
  }
}
</script>

<style scoped>
  section { height: 1750px; }

  h4 hr { border: 1px solid; }
  a { font-family: 'Syne', sans-serif; }

  h1 {
    font-size: 1.2rem;
    padding: 10px;
  }

  h4 {
    font-size: 0.68rem;
    padding: 10px;
  }

  @media screen and (min-width: 991px) {
    section {
      height: unset;
      min-height: 720px;
    }

    h1 {
      font-size: 2.5rem;
      padding: unset;
    }

    h4 {
      font-size: 22px;
      padding: unset;
    }
  }
</style>