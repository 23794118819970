import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

const getAuthToken = () => localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);

export const responseInterceptor = (response: AxiosResponse) => {
    switch (response.status) {
        case 200:
        case 201:
        case 204:
            return response.data;
        default:
            console.error('Ops, si è verificato un errore.');
            break;
    }

    return response;
};

export const errorInterceptor = (error: AxiosError) => {
    // TODO: Case for http status code error
    return error;
}

export const authInterceptor = (config: AxiosRequestConfig) => {
    config.headers['Authorization'] = `Bearer ${getAuthToken()}`;

    return config;
}