import {
    ActionTree,
    CommitOptions,
    createStore,
    DispatchOptions,
    GetterTree,
    MutationTree,
    Store as VuexStore
} from 'vuex';
import {User} from '@/types/User';
import {login, me} from '@/api/auth.api';
import {
    Actions,
    AuthData,
    UserActionTypes,
    UserGetters,
    UserMutations,
    UserMutationTypes,
    UserState
} from '@/store/user/store.types';
import {LoginStatus} from "@/types/LoginStatusEnum";

const state: UserState = {
    user: {} as User,
    token: '' as string,
};

const getters: GetterTree<UserState, UserState> & UserGetters = {
    token: (state: UserState): string => state.token,
    user: (state: UserState): User => state.user,
}

const mutations: MutationTree<UserState> & UserMutations = {
    [UserMutationTypes.SET_TOKEN]: (state: UserState, payload: string) => state.token = payload,
    [UserActionTypes.SET_USER]: (state: UserState, payload: User) => state.user = payload,
}

const actions: ActionTree<UserState, UserState> & Actions = {
    /**
     * async api call for getting
     * user authenticated token
     * @param commit
     * @param payload
     */
    async [UserActionTypes.SET_TOKEN]({ commit }, payload: AuthData): Promise<void | LoginStatus> {
        try {
            const { email, password } = payload;
            const response: any = await login(email, password);

            if (response.error) return Promise.resolve(LoginStatus.NotVerified);

            const token = response.data.token;

            localStorage.setItem(process.env.VUE_APP_TOKEN_NAME, token);

            commit(UserMutationTypes.SET_TOKEN, token);

            return Promise.resolve(LoginStatus.Success);
        } catch (error) {
            // console.log(error);
        }
    },
    /**
     * async api call for getting
     * user autheticated object.
     * @param commit
     */
    async [UserActionTypes.SET_USER]({ commit }): Promise<LoginStatus> {
        try {
            const response = await me();
            const user: User = response.data;

            if (!user) return Promise.resolve(LoginStatus.Invalid);

            commit(UserMutationTypes.SET_USER, user);

            return Promise.resolve(LoginStatus.Success);
        } catch (error) {
            console.log('Errore me');
            return Promise.resolve(LoginStatus.Invalid);
        }
    }
}

/**
 * Create store object
 * with methods previously
 * defined.
 */
export const store = createStore({
    state,
    getters,
    mutations,
    actions,
});

export type Store = Omit<
    VuexStore<UserState>,
    'getters' | 'commit' | 'dispatch'
    > & {
    commit<K extends keyof UserMutations, P extends Parameters<UserMutations[K]>[1]>(
        key: K,
        payload: P,
        options?: CommitOptions
    ): ReturnType<UserMutations[K]>;
} & {
    dispatch<K extends keyof Actions>(
        key: K,
        payload?: Parameters<Actions[K]>[1],
        options?: DispatchOptions
    ): ReturnType<Actions[K]>;
} & {
    getters: {
        [K in keyof UserGetters]: ReturnType<UserGetters[K]>
    };
};