<template>
  <SectionCards />
  <SectionImage
      :read-more="true"
      :show-border-line="true"
      :image-name="'esperienza.jpg'"
      image-alt="Immagine, la nostra esperienza"
      icon-name="esperienza-ico.svg"
      icon-alt="Icona, la nostra esperienza"
      title="La nostra esperienza"
      :description="`
        Studio Commercialista Rizzitiello, professionista sul territorio di Civitavecchia (RM), specializzato in
        adempimenti Fiscali, Contabili, Assistenziali e Servizi CAF.
        Gli anni di esperienza maturata nel settore consentono di fornire consulenze specializzate e
        professionali mirate. Un supporto al cliente ottimizzato per migliorare la gestione Contabile e
        Fiscale, in sintonia con l’evoluzione delle proprie società e/o imprese commerciali.
      `"
      :url="menu.about.url"
  />
  <SectionImage
      :read-more="true"
      :right-image="true"
      :image-name="'modello-730.jpeg'"
      image-alt="Immagine, la nostra esperienza"
      icon-name="caf-blue-ico.svg"
      icon-alt="Icona, caf e cgn: da noi è semplice"
      :title="`Servizi CAF CGN? <br /> Da noi è semplice.`"
      :description="`
         Con l’aiuto del nostro Studio, centro convenzionato e solido punto di riferimento, espletare le
        pratiche in tempi ottimali sarà possibile e senza complicazioni. Scopri tutti i servizi disponibili:
        Servizi Immobili, Camerali, Contabilità, Consulenza e molto altro.
      `"
      :url="menu.cafCgn.url"
  />
  <SectionNews />
  <SectionContactUs />
</template>

<script>
import SectionImage from "@/components/sections/section-image/SectionImage.vue";
import SectionCards from "@/components/sections/section-cards/SectionCards.vue";
import SectionNews from "@/components/sections/section-news/SectionNews.vue";
import SectionContactUs from "@/components/sections/section-contact-us/SectionContactUs.vue";
import webpsupport from '@/mixins/webpsupport.mixin';
import router from '@/router/router.json';

export default {
  name: 'Home',
  mixins: [webpsupport],
  components: {
    SectionContactUs,
    SectionNews,
    SectionCards,
    SectionImage
  },
  data() {
    return {
      menu: router
    }
  },
}
</script>

<style scoped>

</style>
