<template>
  <div class="d-flex justify-content-center py-5 my-5 text-center">
    <div class="w-75">
      <img
          src="@/assets/images/icons/user-dash-ico.svg"
          alt="Icona Utente"
          width="100"
      />
      <div>
        <p class="c-blue pt-3 name">
          {{ fullName }}
        </p>
        <div class="divider"></div>
        <p class="c-blue pt-3 text-uppercase fw-bold">
          {{ isAdmin() ? 'Amministrazione' : 'Area Riservata' }}
        </p>
      </div>
      <div id="main-bar">
        <div v-if="user.is_admin">
          <MainBarButton
            :title="menu.users.title"
            :url="menu.users.url"
          />
          <MainBarButton
              :title="menu.practicesTrashed.title"
              :url="menu.practicesTrashed.url"
          />
          <MainBarButton
              :title="menu.handleArticles.title"
              :url="menu.handleArticles.url"
          />
          <MainBarButton
              :title="menu.communicationsList.title"
              :url="menu.communicationsList.url"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { userMixin } from '@/mixins/user.mixin';
import MainBarButton from '@/components/actions-bar/actions/MainBarButton.vue';
import router from '@/router/router.json';

export default defineComponent({
  name: 'MainBar',
  components: { MainBarButton },
  mixins: [userMixin],
  props: {
    user: {
      type: Object,
      required: false,
    }
  },
  data() {
    return {
      menus: [],
      menu: router
    }
  },
  methods: {
    isAdmin(): boolean {
      if (!this.user) return false;

      return +this.user.is_admin === 1;
    },
    generateBar() {
      const menu = this.$router.currentRoute.value.meta.bar;

      if (!menu) return;

      this.menus = menu;
    }
  },
  mounted() {
    this.generateBar();
  }
})
</script>

<style scoped>
  .name {
    font-size: 20px;
  }

  .divider {
    background-color: #173759;
    width: 100%;
    height: 15px;
  }

</style>