<template>
  <header>
    <div id="white-space" class="mt-5"></div>
    <div class="mt-5"></div>
    <Navbar />
  </header>
  <main id="main">
    <slot />
  </main>
  <Footer />
</template>

<script>
import Navbar from "@/components/template/navbar/Navbar";
import Footer from "@/components/template/footer/Footer";

export default {
name: "News",
  components: {
    Navbar,
    Footer
  }
}
</script>

<style scoped>
  #main {
    min-height: 75vh;
  }
</style>