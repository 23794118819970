<template>
  <header>
    <NavbarContent />
  </header>
  <main>
    <slot />
  </main>
</template>

<script>
import NavbarContent from "@/components/template/navbar/NavbarContent";
export default {
  name: "Blank",
  components: {NavbarContent}
}
</script>

<style scoped>

</style>