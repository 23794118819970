<template>
  <section class="position-relative">
    <img
        v-if="showBorderLine"
        class="blue-stripe"
        src="@/assets/images/banda-blu.png"
        alt="Banda laterale grigia rotonda"
    />
    <div class="container-fluid">
      <article class="row">
        <div class="col-12 col-lg-6" :class="rightImage ? 'order-md-1 pe-lg-4  ps-0' : 'order-0 ps-lg-4 pe-0'">
          <img
              class="w-100 h-100"
              :src="require(`@/assets/images/${imageName}`)"
              :alt="imageAlt ? imageAlt : ''"
          />
        </div>
        <div class="col-12 col-lg-6 d-flex align-items-center px-md-5">
          <div class="content p-3 p-xl-0 text-center" :class="rightImage ? 'text-md-end' : 'text-md-start'">
            <img
                v-if="iconName"
                class="mb-4"
                :src="require(`@/assets/images/icons/${iconName}`)"
                :alt="iconAlt ? iconAlt : ''"
                width="100"
            />
            <h1 class="fw-bold mb-3" v-html="title"></h1>
            <p class="description mb-3 mb-xl-4" :class="rightImage ? 'responsive-margin-left' : 'responsive-margin-right'">
             {{ description }}
            </p>
            <div
                v-if="readMore"
                class="read-more text-uppercase d-flex align-items-center mx-auto mx-md-0"
                :class="rightImage && 'flex-row-reverse ms-md-auto'"
            >
              <div
                  class="bordered-line cursor-pointer"
                  :class="rightImage ? 'ms-2 bg-blue right-animation' : 'me-2 bg-light-gray left-animation'"
              ></div>
              <span
                  class="text-uppercase cursor-pointer fw-bold c-gray"
                  :class="rightImage ? 'c-blue' : 'c-gray'"
                  :style="rightImage
                    ? { right: '25px' }
                    : { left: '25px' }"
              >
                <router-link :to="url ? url : '#'" class="text-decoration-none">
                  Scopri di più
                </router-link>
              </span>
            </div>
          </div>
        </div>
      </article>
    </div>
  </section>
</template>

<script>
export default {
  name: "SectionImage",
  props: {
    imageName: String,
    imageAlt: String,
    iconName: String,
    iconAlt: String,
    title: String,
    description: String,
    showBorderLine: Boolean(false),
    rightImage: Boolean(false),
    readMore: Boolean(false),
    url: String,
  },
}
</script>

<style scoped>
  a {
    color: unset;
  }

  .read-more {
    position: relative;
    max-width: 186px;
  }

  .read-more .bordered-line {
    width: 18px;
    height: 52px;
    border-radius: 20px;
    transition: 0.3s ease-in-out;
  }

  .read-more:hover .bordered-line {
    width: 180px;
    transition: 0.5s ease-in-out;
  }

  .read-more span {
    position: absolute;
  }

  .read-more:hover span {
    color: #fff;
  }

  img.blue-stripe {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 180px;
    z-index: -1;
  }

  p.description {
    font-family: 'Sanchez', sans-serif;
  }

  @media screen and (min-width: 992px) {
    img.blue-stripe {
      width: 400px;
    }

    .content h1 {
      font-size: 2.5rem;
    }

    .content p {
      font-size: 0.95rem;
    }
  }

  @media screen and (min-width: 1400px) {
    .responsive-margin-right {
      margin-right: 150px;
    }
    .responsive-margin-left {
      margin-left: 150px;
    }
  }

  @media screen and (min-width: 1800px) {
    .content h1 {
      font-size: 3rem;
    }
    .content p {
      font-size: 1.32rem;
    }
    .responsive-margin-right {
      margin-right: 400px;
    }
    .responsive-margin-left {
      margin-left: 400px;
    }
  }

</style>