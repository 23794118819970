<template>
  <div class="row h-100">
    <div class="col-6 h-100 d-flex align-items-center">
      <div class="d-flex contacts">
        <div class="px-md-3">
          <a class="c-black d-flex align-items-end" href="tel:+390766220602">
            <img
                src="@/assets/images/icons/phone-ico.svg"
                alt="Icona del Telefono"
                class="me-2"
                width="20"
            />
            <span class="d-none d-md-block">0766 220602</span>
          </a>
        </div>
        <div class="px-md-3">
          <a class="c-black d-flex align-items-end" href="mailto:info@studiorizzitiello.it">
            <img
                src="@/assets/images/icons/mail-ico.svg"
                alt="Icona Informativa"
                class="me-2"
                width="20"
            />
            <span class="d-none d-md-block">info@studiorizzitiello.it</span>
          </a>
        </div>
        <div class="ps-md-3 d-flex align-items-center">
          <span class="d-none d-md-flex">Seguici su</span>
          <a href="#" target="_blank">
            <img
                src="@/assets/images/icons/fb-ico.svg"
                alt="Icona di Facebook"
                class="ms-2"
                width="25"
            />
          </a>
        </div>
      </div>
    </div>
    <div class="col-6 h-100 d-flex align-items-center justify-content-end">
      <div class="icons">
        <img
            id="ordine-commercialisti"
            class="d-none d-md-inline"
            src="@/assets/images/ordine-commercialisti.png"
            alt="Icona dell'ordine dei Commercialisti"
            width="75"
        />
        <img
            id="consiglio-nazionale"
            src="@/assets/images/consiglio-nazionale.png"
            alt="Icona del consiglio Nazionale dei Commercialisti"
            class="ms-md-5"
            width="170"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavbarContainer"
}
</script>

<style scoped>
  .contacts img {
    position: relative;
    top: -3px;
    left: 0;
  }

  a {
    text-decoration: none;
  }

  a:hover {
    color: #173759;
  }

  img#ordine-commercialisti {
    width: 50px;
  }
  img#consiglio-nazionale {
    width: 100px;
  }

  @media screen and (min-width: 768px) {
    img#ordine-commercialisti {
      width: 75px;
    }
    img#consiglio-nazionale {
      width: 170px;
    }
  }
</style>