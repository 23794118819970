<template>
  <div id="navbar"  class="bg-gray">
    <div class="container-fluid container-lg h-100">
      <NavbarContainer />
      <NavbarContent />
    </div>
    <div class="sub-navbar bg-gray"></div>
  </div>
</template>

<script>
import NavbarContent from '@/components/template/navbar/NavbarContent';
import NavbarContainer from '@/components/template/navbar/NavbarContainer';


export default {
  name: "Navbar",
  components: { NavbarContainer, NavbarContent },
}
</script>

<style scoped>
  #navbar {
    position: absolute;
    top: 35px;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100px;
  }

  #navbar .sub-navbar {
    position: relative;
    top: -1px;
    left: 0;
    width: 100%;
    height: 36px;
  }
</style>