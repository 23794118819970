
import { defineComponent } from 'vue';
import { userMixin } from '@/mixins/user.mixin';
import MainBarButton from '@/components/actions-bar/actions/MainBarButton.vue';
import router from '@/router/router.json';

export default defineComponent({
  name: 'MainBar',
  components: { MainBarButton },
  mixins: [userMixin],
  props: {
    user: {
      type: Object,
      required: false,
    }
  },
  data() {
    return {
      menus: [],
      menu: router
    }
  },
  methods: {
    isAdmin(): boolean {
      if (!this.user) return false;

      return +this.user.is_admin === 1;
    },
    generateBar() {
      const menu = this.$router.currentRoute.value.meta.bar;

      if (!menu) return;

      this.menus = menu;
    }
  },
  mounted() {
    this.generateBar();
  }
})
