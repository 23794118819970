<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <button
          class="navbar-toggler text-dark"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#sub-navbar"
          aria-controls="sub-navbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
        </svg>
      </button>
      <div id="sub-navbar" class="collapse navbar-collapse">
        <ul class="navbar-nav m-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link class="nav-link active" :to="menu.dashboard.url">
              {{ menu.dashboard.title }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link active" :to="menu.profile.url">
              {{ menu.profile.title }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link active" :to="menu.userCommunications.url">
              {{ menu.userCommunications.title }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link active" :to="menu.practices.url">
              {{ menu.practices.title }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import router from '@/router/router.json';

export default {
  name: "NavbarDashboard",
  data() {
    return {
      menu: router
    }
  }
}
</script>

<style scoped>
  nav {
    background-color: #bfbfbf;
  }

  .nav-item a {
    color: #173759;
    font-size: 18px;
    margin: 0 55px;
  }

  .nav-link:hover,
  .router-link-exact-active.active {
    background-color: #fff;
    border-radius: 22px;
    text-align: center;
    border: 1px solid #173759;
    padding-right: 20px;
    padding-left: 20px;
  }

  button:focus:not(:focus-visible) {
    color: #173759!important;
    box-shadow: unset;
  }

  .nav-link {
    border-radius: 22px;
  }
</style>