
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref
} from 'vue';
import { notificationAdm, readNotification } from '@/api/notifications.api';
import { useStore } from 'vuex';
import { Notification } from "@/types/Notification";

export default defineComponent({
  name: 'NotificationIcon',
  setup() {
    const store = useStore();
    let notificationsNumber = ref<number>(0);
    let notifications = ref<Notification[]>([]);
    let toggleBody = ref<boolean>(false);

    function toggle() {
      toggleBody.value = !toggleBody.value;

      toggleBody.value && getNotifications();
    }

    async function getNotifications() {
      const response: any = await notificationAdm();
      notifications.value = response.data;
      notificationsNumber.value = response.to_read;
    }

    function onClickAway(): void {
      toggleBody.value && toggle();
    }

    async function goTo(practiceId: number, notificationId: number, viewedAt: any) {
      if (!viewedAt) {
        const response: any = await readNotification(notificationId);

        if (response.status) {
          notificationsNumber.value--;

          const notification = notifications.value.find((notification: Notification) => notification.id === notificationId);
          if (notification) notification.viewed_at = new Date().toLocaleTimeString();
        }
      }

      if (!practiceId) return;

      window.location.href = `${process.env.VUE_APP_AUTH_GUARD_PATHNAME}/practices/${practiceId}/documents`;
      toggleBody.value && toggle();
    }

    function createDate(date: string) {
      return new Date(date).toLocaleDateString() + ' ' + new Date(date).toLocaleTimeString();
    }

    onMounted(() => {
      getNotifications();

      setInterval(getNotifications, 60000);
    });

    onUnmounted(() => {
      //@ts-ignore
      clearInterval(getNotifications);
    });

    return {
      notificationsNumber,
      toggleBody,
      notifications,
      toggle,
      goTo,
      createDate,
      onClickAway,
      isAdmin: computed(() => !!store.getters.user.is_admin),
    }
  }
})
