<template>
  <header>
    <Navbar />
  </header>
  <main id="main">
    <Slider
      v-if="show"
      :title="`Consulenza su Misura <br /> per un’assistenza di valore.`"
      image="slider-1.png"
    />
    <Slider
      v-if="!show"
      :title="`Supporto a 360° <br /> per la tua azienda`"
      image="slider-2.png"
    />
    <slot />
  </main>
  <Footer />
</template>

<script>
import Navbar from "@/components/template/navbar/Navbar";
import Slider from "@/components/slider/main/Slider";
import Footer from "@/components/template/footer/Footer";

export default {
  name: "WithSlider",
  components: {
    Footer,
    Slider,
    Navbar
  },
  data() {
    return {
      timer: 16000,
      show: false,
    }
  },
  methods: {
    toggleShow() {
      this.show = !this.show
    }
  },
  mounted() {
    setInterval(() => this.toggleShow(), this.timer);
  },
  unmounted() {
    clearInterval(this.toggleShow);
  }
}
</script>

<style scoped>

</style>