import { store } from '@/store/user';
import { UserActionTypes, UserMutationTypes } from '@/store/user/store.types';
import router from '@/router';

/**
 * This method handle authentication:
 * It solves a problem with the persistence.
 *
 * When we refresh a page, vuex lost data
 * about token and about user logged in.
 *
 * Starting from token previously saved in
 * localStorage, we can verify who he is
 * (also if he refresh the web app)
 * calling the "me" end point (api)
 *
 * If token doesn't exists, it means that
 * he need to log-in from scratch.
 */
export const isLoggedIn = async () => {
    const pathName = window.location.pathname;

    /**
     * If user isn't in /management/* url path,
     * we don't need authentication so, let's continue
     * visiting our web app.
     */
    if (!pathName.includes(process.env.VUE_APP_AUTH_GUARD_PATHNAME)) {
        return Promise.resolve();
    }

    /**
     * Otherwise, if user is under /management/* url path,
     * we need to check if token exist and if exists,
     * we need to send http request to know who he is.
     * Then he can see the control panel.
     */
    const token = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);

    /**
     * Token doesn't exist, let's authenticate!
     */
    if (!token) return router.push('/login');

    /**
     * We have a token, let's find out who he is.
     * First we commit token in vuex store.
     * Second we send http request to "me" endpoint.
     */
    await store.commit(UserMutationTypes.SET_TOKEN, token);
    await store.dispatch(UserActionTypes.SET_USER);

    return Promise.resolve();
}

export const logout = () => {
    const token = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);

    if (!token) return;

    localStorage.removeItem(process.env.VUE_APP_TOKEN_NAME);

    return router.push('/login');
}