<template>
  <div class="w-75 m-auto bar-button mb-3">
    <router-link :to="url" class="nav-link c-blue">
      {{ title }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: "MainBarButton",
  props: {
    title: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    }
  }
}
</script>

<style scoped>
  .bar-button {
    border: 2px solid #bfbfbf;
  }

  .nav-link {
    color: #173759;
  }

  .router-link-active.router-link-exact-active {
    background-color: #bfbfbf;
  }
</style>