<template>
  <div class="bell-notification" @click="toggle">
    <div v-if="notificationsNumber" class="notifications-number">
      <span class="number center position-absolute">
        {{ notificationsNumber }}
      </span>
    </div>
    <i class="bi bi-bell cursor-pointer"></i>
  </div>
  <div v-if="toggleBody" class="notifications-body" v-click-away="onClickAway">
    <div class="container-fluid scrollable" style="cursor: default">
      <div
        v-for="notification in notifications"
        :key="notification.id"
        class="row single-notification cursor-pointer"
        style="height: 90px"
        @click="goTo(notification.practice_id, notification.id, notification.viewed_at)"
      >
        <div class="col-3 d-flex justify-content-center align-items-center">
          <i
            class="bi bi-bell-fill big-icon"
            :class="notification.viewed_at ? 'c-gray' : 'c-blue'"
          ></i>
        </div>
        <div class="col-9 d-flex justify-content-center align-items-center flex-column">
          <p class="notification-description c-blue mb-0">
            {{ notification.executed_name + ' ' + notification.message.toLowerCase() }}
          </p>
          <div class="time">
            {{ createDate(notification.created_at) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref
} from 'vue';
import { notificationAdm, readNotification } from '@/api/notifications.api';
import { useStore } from 'vuex';
import { Notification } from "@/types/Notification";

export default defineComponent({
  name: 'NotificationIcon',
  setup() {
    const store = useStore();
    let notificationsNumber = ref<number>(0);
    let notifications = ref<Notification[]>([]);
    let toggleBody = ref<boolean>(false);

    function toggle() {
      toggleBody.value = !toggleBody.value;

      toggleBody.value && getNotifications();
    }

    async function getNotifications() {
      const response: any = await notificationAdm();
      notifications.value = response.data;
      notificationsNumber.value = response.to_read;
    }

    function onClickAway(): void {
      toggleBody.value && toggle();
    }

    async function goTo(practiceId: number, notificationId: number, viewedAt: any) {
      if (!viewedAt) {
        const response: any = await readNotification(notificationId);

        if (response.status) {
          notificationsNumber.value--;

          const notification = notifications.value.find((notification: Notification) => notification.id === notificationId);
          if (notification) notification.viewed_at = new Date().toLocaleTimeString();
        }
      }

      if (!practiceId) return;

      window.location.href = `${process.env.VUE_APP_AUTH_GUARD_PATHNAME}/practices/${practiceId}/documents`;
      toggleBody.value && toggle();
    }

    function createDate(date: string) {
      return new Date(date).toLocaleDateString() + ' ' + new Date(date).toLocaleTimeString();
    }

    onMounted(() => {
      getNotifications();

      setInterval(getNotifications, 60000);
    });

    onUnmounted(() => {
      //@ts-ignore
      clearInterval(getNotifications);
    });

    return {
      notificationsNumber,
      toggleBody,
      notifications,
      toggle,
      goTo,
      createDate,
      onClickAway,
      isAdmin: computed(() => !!store.getters.user.is_admin),
    }
  }
})
</script>

<style scoped>
  .bell-notification {
    position: absolute;
    top: 50%;
    left: calc(100% - 115px);
    transform: translateY(-50%);
  }

  .notifications-number {
    position: absolute;
    width: 26px;
    height: 26px;
    background-color: #d11b1be6;
    border-radius: 50%;
    top: -10px;
    left: 16px;
  }

  .notifications-number .number {
    font-size: 0.85rem;
  }

  .notifications-body {
    position: absolute;
    width: 100%;
    height: 70vh;
    top: 81px;
    left: 0;
    cursor: default;
    background-color: #f5f5f5;
  }

  .scrollable {
    overflow-y: scroll;
    height: 70vh;
  }

  .single-notification {
    border-bottom: 1px solid #173759;
  }

  .single-notification:hover {
    background-color: #173759;
    color: #fff;
    border-bottom: 1px solid #fff;
  }

  .single-notification:hover .time,
  .single-notification:hover .big-icon.c-blue,
  .single-notification:hover .notification-description {
    color: #fff;
  }

  .notification-description {
    font-size: 0.9rem;
  }

  .time {
    font-weight: 500;
    font-size: 1rem;
    color: #173759;
  }

  i.big-icon {
    font-size: 2rem;
  }

  ::-webkit-scrollbar {
    width: 12px;               /* width of the entire scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: #f4f4f4;        /* color of the tracking area */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #173759;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3px solid #f4f4f4;  /* creates padding around scroll thumb */
  }

  @media screen and (min-width: 972px) {
    .notifications-body {
      width: 400px;
      height: 70vh;
      top: 71px;
      left: -228px;
    }

    .bell-notification {
      position: relative;
      top: unset;
      left: unset;
      transform: unset;
    }
  }
</style>