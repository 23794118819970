
import { defineComponent } from "vue";
import router from '@/router/router.json';
import { logout } from "@/utilities/auth";
import { userMixin } from "@/mixins/user.mixin";
import NotificationIcon from "@/components/actions-bar/notifications/NotificationIcon.vue";

const MIN_SCROLL_Y = 150;

export default defineComponent({
  name: "NavbarContent",
  components: {NotificationIcon},
  mixins: [userMixin],
  props: {
    management: {
      type: Boolean,
      required: false,
      default: false,
    },
    user: {
      type: Object,
      required: false,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      menu: router,
    }
  },
  methods: {
    redirect(pathname: string) {
      window.location.href = pathname;
    },
    responsive() {
      return window.innerWidth > 972;
    },
    logout() {
      logout();
    }
  },
  mounted() {
    if (!location.href.includes(process.env.VUE_APP_AUTH_GUARD_PATHNAME)) {
      document.addEventListener('scroll', () => {
        if (!this.$refs.navbar) return;

        const y = window.scrollY;

        if (y > MIN_SCROLL_Y) {
          (this.$refs.navbar as HTMLElement).style.transition = '0.9s ease-in';
          (this.$refs.navbar as HTMLElement).style.position = 'fixed';
          (this.$refs.navbar as HTMLElement).style.top = '0';
          (this.$refs.navbar as HTMLElement).style.left = '0';
          (this.$refs.navbar as HTMLElement).style.width = '100%';
        } else {
          (this.$refs.navbar as HTMLElement).style.position = 'relative'
        }
      });
    }
  },
  unmounted() {
    document.removeEventListener('scroll', () => {/**/});
  }
})
