export default {
    computed: {
        isWebpSupported() {
            const fakeCanvas = document.createElement('canvas');

            return fakeCanvas.getContext && fakeCanvas.getContext('2d')
                ? fakeCanvas.toDataURL('image/webp').indexOf('data:image/webp') === 0
                : false;
        }
    }
}