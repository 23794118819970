<template>
  <header>
    <NavbarContent
      :management="true"
      :user="user"
    />
    <NavbarDashboard />
  </header>
  <main>
    <div class="container-xl">
      <div class="row">
        <div class="col-md-3">
          <MainBar :user="user" />
        </div>
        <div class="col-md-9 py-5 my-4">
          <slot />
        </div>
      </div>
    </div>
  </main>
  <footer style="margin-top: 200px">
  </footer>
</template>

<script>
import NavbarContent from "@/components/template/navbar/NavbarContent";
import NavbarDashboard from "@/components/template/navbar/NavbarDashboard";
import MainBar from "@/components/template/siderbar/MainBar";
import Footer from "@/components/template/footer/Footer";

export default {
name: "Dashboard",
  components: {
    Footer,
    MainBar,
    NavbarDashboard,
    NavbarContent
  },
  data() {
    return {
      user: this.$store.getters.user
    }
  },
}
</script>

<style scoped>

</style>