<template>
  <article @mouseenter="showHover()"
           @mouseleave="hideHover()"
           class="rounded-card box-shadow overflow-hidden mb-5 mb-xl-0"
  >
    <div v-if="show" class="overlay scale-in-center" @click="redirect()">
      <img id="b-blue" src="@/assets/images/banda-blu-mini.png" alt="Banda blu" />
      <img id="b-white" src="@/assets/images/banda-bianca-mini.png" alt="Banda blu" />
      <div class="read-more">
        <p class="text-uppercase fw-bold text-white">
          Scopri di più
        </p>
      </div>
    </div>
    <img
        class="w-100 h-100 obj-fit-cover"
        :src="require(`@/assets/images/${imageName}`)"
        :alt="imageAlt ? imageAlt : ''"
    />
    <div class="footer ps-4">
      <div class="d-flex align-items-center">
        <img
            :src="require(`@/assets/images/icons/${iconName}`)"
            :alt="iconAlt ? iconAlt : ''"
            width="70"
        />
        <div class="text-uppercase c-blue fw-bold" v-html="description"></div>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: "RoundedCard",
  props: {
    imageName: String,
    imageAlt: String,
    iconName: String,
    iconAlt: String,
    description: String,
    url: String,
  },
  data() {
    return {
      show: false,
    }
  },
  methods: {
    showHover() {
      this.show = true;
    },
    hideHover() {
      this.show = false;
    },
    redirect() {
      if (!this.url) return;

      this.$router.push(this.url);
    }
  }
}
</script>

<style scoped>
  .rounded-card {
    position: relative;
    width: 400px;
    height: 520px;
    border-top-left-radius: 32px;
    border-bottom-right-radius: 32px;
    background: #fff;
    z-index: 9;
    cursor: pointer;
  }

  .rounded-card .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    z-index: 1;
    background-color: #fff;
  }

  .rounded-card .footer img {
    position: relative;
    top: -10px;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #173759a6;
    width: 100%;
    height: 100%;
    z-index: 9;
  }

  .overlay img#b-blue {
    position: absolute;
    top: 0;
    left: 0;
    width: 220px;
  }

  .overlay img#b-white {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 140px;
  }

  .overlay .read-more {
    position: absolute;
    top: 305px;
    left: 20px;
    z-index: 2;
  }

  .overlay .read-more p {
    font-size: 1.15rem;
  }

</style>