import { ActionContext } from 'vuex';
import { User } from '@/types/User';
import {LoginStatus} from "@/types/LoginStatusEnum";

/**
 * State for user store
 */
export type UserState = {
    token: string;
    user: User;
};

/**
 * Mutation types for user store
 */
export enum UserMutationTypes {
    SET_USER = 'SET_USER',
    SET_TOKEN = 'SET_TOKEN',
}

/**
 * Actions types for user store
 */
export enum UserActionTypes {
    SET_USER = 'SET_USER',
    SET_TOKEN = 'SET_TOKEN',
}

/**
 * AugmentedActionContext do the job,
 * is restricts committing only the declared
 * mutations (it also checks payload type).
 */
type AugmentedActionContext = {
    commit<K extends keyof UserMutations>(
        key: K,
        payload: Parameters<UserMutations[K]>[1],
    ): ReturnType<UserMutations[K]>;
} & Omit<ActionContext<UserState, UserState>, "commit">;

/**
 * Type for AuthData payload
 */
export type AuthData = {
    email: string;
    password: string;
}

/**
 * Interface for Actions
 */
export interface Actions {
    [UserActionTypes.SET_TOKEN](
        {commit}: AugmentedActionContext,
        payload: AuthData
    ): Promise<void | LoginStatus>;
    [UserActionTypes.SET_USER](
        {commit}: AugmentedActionContext
    ): Promise<LoginStatus>;
}

/**
 * Mutation type for user store
 */
export type UserMutations<S = UserState> = {
    [UserMutationTypes.SET_TOKEN](state: S, payload: string): void;
    [UserMutationTypes.SET_USER](state: S, payload: User): void;
};

/**
 * Getters type for user store
 */
export type UserGetters = {
    token(state: UserState): string;
    user(state: UserState): User;
}