import httpClient from "@/api/httpClient";

const END_POINT = '/news';

const createArticle = (article: any) => httpClient.post(
    END_POINT,
    { ...article }
);

const paginateArticles = (page: number = 1) => httpClient.get(
    END_POINT + '?page=' + page
);

const showArticle = (id: number) => httpClient.get(
    END_POINT + '/' + id
);

const updateArticle = (id: number, article: any) => httpClient.patch(
    END_POINT + '/' + id,
    { ...article }
);

const deleteArticle = (id: number) => httpClient.delete(
    END_POINT + '/' + id
)

export {
    createArticle,
    paginateArticles,
    showArticle,
    updateArticle,
    deleteArticle
}