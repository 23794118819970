import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import '@/assets/css/style.css';
import VueClickAway from "vue3-click-away";

import { store } from "@/store/user";

import Default from '@/layouts/Default.vue';
import WithSlider from '@/layouts/WithSlider.vue';
import News from '@/layouts/News.vue';
import Blank from '@/layouts/Blank.vue';
import Dashboard from "@/layouts/Dashboard.vue";
import { isLoggedIn } from "@/utilities/auth";

const app = createApp(App);

// @ts-ignore
//window.__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue = app;

app.component('default-layout', Default);
app.component('slider-layout', WithSlider);
app.component('news-layout', News);
app.component('blank-layout', Blank);
app.component('dashboard-layout', Dashboard);

isLoggedIn()
    .then(() => {
        app.use(router)
            .use(store)
            .use(VueClickAway)
            .mount('#app');
    });


