<template>
  <form id="contact-us" class="row g-3">
    <div class="col-md-12">
      <input type="text"
             class="form-control"
             placeholder="Oggetto richiesta"
      />
    </div>
    <div class="col-6">
      <input type="text"
             class="form-control"
             placeholder="Nome"
      />
    </div>
    <div class="col-6">
      <input type="text"
             class="form-control"
             placeholder="Cognome"
      />
    </div>
    <div class="col-6">
      <input type="tel"
             class="form-control"
             placeholder="Telefono"
      />
    </div>
    <div class="col-6">
      <input type="email"
             class="form-control"
             placeholder="Mail"
      />
    </div>
    <div class="col-12 mb-4">
      <div class="form-floating">
        <textarea id="message"
                  class="w-100 form-control"
                  placeholder="Messaggio"
                  style="height: 100px"
        >
        </textarea>
        <label for="message">Messaggio</label>
      </div>
    </div>
    <div class="col-7 d-flex align-items-end">
      <div class="form-check d-flex align-items-end ">
        <input class="form-check-input m-0 me-2 mb-1" type="checkbox" value="" id="privacy">
        <label class="form-check-label text-white fw-bold privacy-label" for="privacy">
          Ho letto e accetto la Privacy Policy
        </label>
      </div>
    </div>
    <div class="col-5 d-flex justify-content-end pe-3">
      <button
          type="button"
          class="btn bg-dark-gray w-75"
      >
        Invia
      </button>
    </div>
  </form>
</template>

<script>
export default {
  name: "ContactForm"
}
</script>

<style scoped>
  form#contact-us {
    position: relative;
    top: 240px;
  }

  .form-control:focus {
    background-color: unset;
    box-shadow: unset;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    color: #fff;
  }

  .form-floating label {
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
  }

  textarea {
    resize: none;
  }

  form input {
    margin-bottom: 1.35rem;
  }

  textarea,
  input:not([type='checkbox']) {
    border-radius: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    background-color: unset;
    border-color: #f2f3f4;
    color: #fff;
  }

  ::placeholder {
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
  }

  .privacy-label {
    font-size: 0.85rem;
  }
</style>