<template>
  <footer
    id="footer"
    class="position-relative py-5 overflow-hidden bg-jpg"
  >
    <div class="overlay"></div>
    <div class="container position-relative" style="z-index: 1">
      <div class="row">
        <div class="col-md-12 col-lg-3">
          <img
              src="@/assets/images/logo-footer.png"
              alt="Logo Studio Rizzitiello"
              class="p-4 p-md-1 w-100"
          />
        </div>
        <div class="col-md-1 mb-3 mb-md-0 mt-4"></div>
        <div class="col-md-3 mb-3 mb-md-0 mt-4">
          <h1 class="fw-bold text-white mb-4">
            Servizi
          </h1>
          <ul class="footer-link text-white p-0 list-unstyled">
            <li>
              <router-link class="text-white" :to="menu.consulenzaFiscaleEContabile.url">
                Consulenza Fiscale e contabile
              </router-link>
            </li>
            <li>
              <router-link :to="menu.consulenzaLavoro.url" class="text-white">
                Consulenza del lavoro
              </router-link>
            </li>
            <li>
              <router-link :to="menu.cafCgn.url" class="text-white">
                Caf Cgn
              </router-link>
            </li>
          </ul>
          <h1 class="fw-bold text-white mb-4">
            Link Utili
          </h1>
          <ul class="footer-link text-white p-0 list-unstyled">
            <li>
              <a class="text-white" href="https://www.inps.it/nuovoportaleinps/default.aspx" target="_blank">
                INPS
              </a>
            </li>
            <li>
              <a href="https://www.inail.it/cs/internet/home.html" target="_blank" class="text-white">
                INAIL
              </a>
            </li>
            <li>
              <a href="https://www.agenziaentrate.gov.it/portale/" target="_blank" class="text-white">
                Agenzia delle entrate
              </a>
            </li>
          </ul>
        </div>
        <div class="col-md-2 mb-3 mb-md-0 mt-4">
          <h1 class="fw-bold text-white mb-4">
            Studio
          </h1>
          <ul class="footer-link text-white p-0 list-unstyled">
            <li>
              <router-link :to="menu.news.url" class="text-white">
                News
              </router-link>
            </li>
            <li>
              <router-link :to="menu.about.url" class="text-white">
                Chi Siamo
              </router-link>
            </li>
            <li>
              <div class="mb-2">
                Seguici su
              </div>
              <router-link target="_blank" to="#">
                <img
                    src="@/assets/images/icons/fb-ico.svg"
                    alt="Icona di Facebook"
                    width="30"
                />
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col-md-3 mb-3 mb-md-0 mt-4">
          <h1 class="fw-bold text-white mb-4">
            Contatti
          </h1>
          <ul class="footer-link text-white p-0 list-unstyled">
            <li>
              <a target="_blank" class="text-white" href="http://maps.google.com/?q=1200 Via Traiana, 73 Civitavecchia, RM 00053">
                Via Traiana, 73 <br />
                Civitavecchia, RM 00053
              </a>
               <br />

            </li>
            <li>
              <a class="text-white" href="tel:+390766220602">0766 220602</a>
            </li>
            <li>
              <a class="text-white" href="mailto:info@studiorizzitiello.it">info@studiorizzitiello.it</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import webpsupport from '@/mixins/webpsupport.mixin';
import router from '@/router/router.json';

export default {
  name: "Footer",
  mixins: [ webpsupport ],
  data() {
    return {
      menu: router,
    }
  }
}
</script>

<style scoped>
  #footer {
    background-position: center;
    background-size: cover;
    height: 1100px;
  }

  #footer.bg-webp {
    background-image: url('../../../assets/images/footer.webp');
  }

  #footer.bg-jpg {
    background-image: url('../../../assets/images/footer2.png');
  }

  h1 {
    font-size: 1.45rem;
  }

  #footer ul li,
  #footer ul li a {
    font-size: 0.98rem;
  }

  h1, h2, h3, h4 {
    font-family: 'Syne', sans-serif;
  }

  ul.footer-link li {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }

  a {
    text-decoration: none;
  }

  @media screen and (min-width: 992px) {
    #footer {
      height: 500px;
    }
  }
</style>