import httpClient from "@/api/httpClient";

const END_POINT = '/notifications';

const notificationAdm = () => httpClient.get(
    END_POINT
);

const readNotification = (notificationId: number) => httpClient.get(
    END_POINT + '/' + notificationId + '/views'
)

export {
    notificationAdm,
    readNotification
}