<template>
  <header class="position-relative">
    <HeaderWithTitle />
    <Navbar />
  </header>
  <main id="main">
    <slot />
  </main>
  <div class="mt-5"></div>
  <Footer />
</template>

<script>
import Navbar from "@/components/template/navbar/Navbar";
import Footer from "@/components/template/footer/Footer";
import HeaderWithTitle from "@/components/template/HeaderWithTitle";

export default {
  name: "Default",
  components: {
    HeaderWithTitle,
    Navbar,
    Footer
  }
}
</script>

<style scoped>

</style>