<template>
  <section
    v-if="image && title"
    class="slider scale-in-hor-right vh-100"
    :style="{backgroundImage: 'url(' + require(`../../../assets/images/slider/${image}`) +')'}"
  >
    <div class="container h-100">
      <div class="row pt-230p h-100">
        <div class="col-12 h-100 d-flex align-items-end pb-5">
          <div class="position-relative slider-content px-md-5 mb-5">
            <h1 class="title starter fw-bold" v-html="title"></h1>
            <h5 class="description starter mt-4" v-html="description"></h5>
            <button class="starter btn bg-blue text-uppercase mt-4">
              <a href="#contact">
                Fissa un Appuntamento
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Slider",
  props: {
    title: String,
    description: String,
    image: String,
  },
  methods: {
    animate(element, timer) {
      setTimeout(() => {
        element.classList.remove('starter');
        element.classList.add('scale-in-hor-left');
      }, timer);
    },
  },
  mounted() {
    this.animate(document.querySelector('.title'), 600);
    this.animate(document.querySelector('.description'), 800);
    this.animate(document.querySelector('.btn'), 900);

  },

}
</script>

<style scoped>
  a {
    color: unset;
    text-decoration: none;
  }

  .slider {
    background-position: center;
    background-size: cover;
  }

  .slider.bg-jpg {

  }

  .pt-230p {
    padding-top: 230px;
  }

  .slider .slider-content h1 {
    font-size: 2rem;
  }

  .slider .slider-content h5 {
    font-size: 0.85rem;
  }

  .starter {
    position: relative;
    visibility: hidden;
  }

  @media screen and (min-width: 992px) {
    .slider .slider-content h1 {
      font-size: 3.2rem;
    }

    .slider .slider-content h5 {
      font-size: 1.25rem;
    }
  }

</style>