import httpClient from "@/api/httpClient";
import { UserRegister } from "@/types/User";

const END_POINT = '/auth/';
const LOGIN_END_POINT = 'login';
const REGISTER_END_POINT = 'register';
const ME_END_POINT = 'me';

const login = (email: string, password: string) => httpClient.post(
    END_POINT + LOGIN_END_POINT,
    { email, password}
);

const register = (data: UserRegister) => httpClient.post(
    END_POINT + REGISTER_END_POINT,
    { ...data }
);

const me = () => httpClient.get(END_POINT + ME_END_POINT);

export {
    login,
    register,
    me,
};